.react-datepicker__month-text {
  padding: 10px;
}

.MuiAutocomplete-option.Mui-focused {
  background-color: #ccc !important;
}

.MuiTableBody-root tr:last-child .MuiTableCell-root {
  border-bottom: 0 !important;
}
